<template>
  <div class="flex flex-1 flex-col p-1 relative">
    <a>
      <div class="border-b-4 border-untitled-gray-800 cursor-pointer">
        <div @click="goToLearningPath" class="h-48 w-full bg-black bg-opacity-25 relative">
          <img
            v-lazy="{ src: thumbnail, error: 'https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg', loading: 'https://via.placeholder.com/800x450?text=Thumbnail%20Loading...' }"
            class="w-full h-full object-cover object-center"
          />
          <div
            class="absolute md:w-[100%] bottom-0 h-full object-center inset-0 place-items-center bg-gradient-to-t from-black opacity-50 text-white text-xs text-center leading-4"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="mt-[18%] object-center place-items-center inset-0 mx-auto w-20 h-20"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
              />
            </svg>
          </div>
        </div>
        <div class="min-h-[150px] max-h-[150px] shadow-md px-2">
          <div class="w-full">
            <h5 class="text-untitled-gray-800 my-4 font-medium">{{ title }}</h5>
          </div>
          <div class="flex peer-hover:hidden beer-hover:hidden items-center mb-1 text-untitled-gray-600">
            <VideoCameraIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
            <span class="mx-2">|</span>

            <p class="ml-4 text-xs">{{ totalVideos }} videos</p>
          </div>
          <template v-if="dueDate">
            <div class="flex peer-hover:hidden items-center mb-1 text-untitled-gray-600">
              <CalendarIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
              <span class="mx-2">|</span>

              <p class="ml-4 text-xs">{{ dueDate }}</p>
            </div>
          </template>
          <div class="flex peer-hover:hidden items-center text-untitled-gray-600">
            <PresentationChartLineIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
            <span class="mx-2">|</span>
            <p class="ml-4 text-xs">
              {{ totalVideosCompleted }}/{{ totalVideos }} videos completed
            </p>
          </div>

          <Menu as="div" class="absolute top-1/2 right-0 text-center" v-if="isSuperAdmin || isOwner" >
            <div>
              <MenuButton class="inline-flex justify-center rounded-md  pt-4 text-sm font-medium text-gray-700">
                <DotsVerticalIcon class="h-6 w-6 mr-1 mt-2" aria-hidden="true" />
              </MenuButton>
            </div>

            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div class="py-1">
                  <MenuItem v-slot="{ active }" v-if="!hasPaidContents">
                    <a href="#"  @click="openDialog()" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Assign Playlist</a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <router-link :to="'/edit/playlist/' + pathId" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Edit Playlist</router-link>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a href="#"  @click="confirmShow = true" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Delete Playlist</a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>

        </div>
      </div>
    </a>
  </div>
  <assign-playlist-dialog v-if="assignPlaylistDialogOpen" :is-open="assignPlaylistDialogOpen" :close="() => {assignPlaylistDialogOpen = false}" :playlist-id="pathId"/>
  <ConfirmDialog
    :okButton="deletePlaylist"
    :cancelButton="onClickConfirmCancel"
    :open="confirmShow"
    id="UnitPage-ConfirmDialog-Dialogbox"
  >{{ confirmMessage }}
  </ConfirmDialog>
</template>

<script setup>
import AssignPlaylistDialog from "@/components/learning-paths/AssignPlaylistDialog.vue";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { defineProps, ref, inject, computed } from "vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores";
import {
  DotsVerticalIcon,
  // DotsHorizontalIcon,
  VideoCameraIcon,
  CalendarIcon,
  // CogIcon,
  PresentationChartLineIcon,
} from "@heroicons/vue/outline";
import { find as _find } from "lodash-es";
import { useLearningPathStore } from '@/stores/learningPath';

const props = defineProps({
  contents: {
    type: Array,
    default: () => [],
  },
  isOwner: {
    type: Boolean,
    required: true,
  },
  reload: {
    type: Function,
  },
  pathId: {
    type: Number,
    required: true,
  },
  title: {
    type: String,
    default: null,
  },
  totalVideoDuration: {
    type: String,
    default: null,
  },
  totalVideos: {
    type: Number,
    default: 0,
  },
  totalVideosCompleted: {
    type: Number,
    default: 0,
  },
  videoThumbnail: {
    type: String,
    default: null,
  },
  dueDate: {
    type: String,
    default: null,
  },
  thumbnail: {
    type: String,
    default: null,
  },
  hasPaidContents: {
    type: Boolean,
    default: false
  }
});
const authUser = useAuthStore();
const confirmMessage = ref("Are you sure you want to delete this playlist?");
const confirmShow = ref(false);
const axios = inject("axios");
const learningPath = useLearningPathStore();
const hasPaidContents = computed(() => props.hasPaidContents)

const isSuperAdmin = computed(() => {
  const user = authUser.$state.user;
  if (!user){
    return false;
  }
  const isExists = user.roles.find(
    (role) => role.name === "super-administrator"
  );

  return isExists;
});

const router = useRouter();
const assignPlaylistDialogOpen = ref(false);
function goToLearningPath() {
  const { contents, pathId } = props;

  const currentUnit = _find(contents, { is_completed: false });

  let unitId = null;

  if (currentUnit === undefined) {
    unitId = props.contents[0].id;
  } else {
    unitId = currentUnit.id;
  }

  router.push({
    name: "units",
    params: {
      id: unitId,
      playlistId: pathId,
    },
  });
}
function openDialog(){
  assignPlaylistDialogOpen.value = true
}
async function deletePlaylist() {
  await axios
    .delete(`/api/v3/learning-paths/${props.pathId}`)
    .then(() => {
      learningPath.removeLearningPath(props.pathId);
      confirmShow.value = false
    });
}
function onClickConfirmCancel(){
  confirmShow.value = false
}
</script>
<style scoped>

</style>