<template>
    <div class="flex flex-col">
      <label class="block text-sm font-medium text-untitled-gray-700">Due Date</label>
      <div class="w-full">
        <v-date-picker :min-date="new Date()" class="text-sm" v-model="dueDate" enableTimePicker name="date" />
      </div>
    </div>
    <div class="flex flex-col mt-2">
      <label class="block text-sm font-medium text-untitled-gray-700">Assign to users</label>
      <e-combobox :disabled="!dueDate" v-model="selectedUsers" name="users" :options="users" :loading="fetchingLoading" option-name="name"
        value-key="id" :placeholder="!dueDate ? 'Please set a due date first' : 'Start typing to search users....'" :multiple="true" :filterable="true"
        :clearable="true" />
    </div>
    <div class="flex flex-col mt-2">
      <div class="flex justify-between items-center mb-1" >
        <label class="block text-sm font-medium text-untitled-gray-700">Assign to groups</label>
        <a v-if="dueDate" class="cursor-pointer px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm true text-center h-[auto] w-[auto] justify-center inline-flex items-center text-untitled-gray-800 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500" 
          @click="handleClickCreateGroup">
          Create Group
        </a>
      </div>
      <e-combobox :disabled="!dueDate" v-model="selectedGroups" name="groups" :options="groups" :loading="fetchingLoading" option-name="name"
        value-key="id" :multiple="true" :filterable="true" :clearable="true"
        :placeholder="!dueDate ? 'Please set a due date first' : 'Start typing to search groups....'" />
    </div>
    <div v-if="false" class="flex flex-col mt-2">
      <label class="block text-sm font-medium text-untitled-gray-700">Assign to dealers</label>
      <e-combobox :disabled="!dueDate" v-model="selectedDealers" name="dealers" :options="dealers" option-name="name" value-key="id" :multiple="true" :filterable="true" :clearable="true" :placeholder="!dueDate ? 'Please set a due date first' : 'Start typing to search dealers....'" />
    </div>

    <div v-if="dueDate && (selectedUsers.length === 0 && selectedGroups.length === 0 && selectedDealers.length === 0)" class="mt-2">
      <p class="text-sm text-red-600">{{ errorMsg }}</p>
    </div>

  <div class="flex mt-5 justify-end ">
    <v-button :disabled="(selectedUsers.length === 0 && selectedGroups.length === 0 && selectedDealers.length === 0)" type="button"
      @click="handleSubmit()"
      class="justify-center inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-untitled-gray-800 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500 "
      :loading="loading">Save</v-button>
    <v-button type="button"
      class="ml-2 inline-flex  justify-center rounded-md border border-untitled-gray-300 bg-white px-4 py-2 text-base font-medium text-untitled-gray-700 shadow-sm hover:text-untitled-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
      @click="close()" ref="cancelButtonRef" :loading="loading">Cancel</v-button>
  </div>


</template>

<script setup>
import { ref, defineEmits, defineProps } from "vue";
import { VDatePicker, VButton } from 'revdojo-vue-components'
import ECombobox from "@/components/element-components/ECombobox"

defineProps({
  close: {
    type: Function,
    default: () => { }
  },
  users: {
    default: []
  },
  groups: {
    default: []
  },
  dealers: {
    default: []
  }
})

const selectedUsers = ref([]);
const selectedGroups = ref([]);
const selectedDealers = ref([])
const dueDate = ref(null);
const errorMsg = ref("Atleast one user or group or dealer is required");
const emit = defineEmits(['createGroup', 'assign']);
const loading = ref(false);
const fetchingLoading = ref(false);
const setPayload = () => {
  return {
    assigned_users: selectedUsers.value,
    assigned_groups: selectedGroups.value,
    assigned_dealers: selectedDealers.value,
    due_date: dueDate.value
  }
}
const handleSubmit = () => {
  emit('assign', setPayload());
}
const handleClickCreateGroup = () => {
emit('createGroup');
}
</script>

